/* eslint-disable no-unused-vars */
import jwt from 'jsonwebtoken';
import Vue from 'vue';
import VueRouter from 'vue-router';

import Model from '../views/Model.vue';
import ModelSet from '../views/ModelSet.vue';
import Pack from '../views/Pack.vue';
import ExtraContent from '../views/ExtraContent.vue';
import User from '../views/User.vue';
import Login from '../views/Login.vue';
import Tag from '../views/Tag.vue';
import RecoverPassword from '../views/RecoverPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Orders from '../views/Orders.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
      public: true,
    },
  },
  {
    path: '/recover-password',
    name: 'recoverPassword',
    component: RecoverPassword,
    meta: {
      title: 'Recuperar senha',
      public: true,
    },
  },
  {
    path: '/reset-password/:recoveryToken',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      title: 'Definir nova senha',
      public: true,
    },
  },
  {
    path: '/',
    name: 'home',
    redirect: '/models',
  },
  {
    path: '/models',
    name: 'models',
    component: Model,
    meta: {
      title: 'Models',
    },
  },
  {
    path: '/model-sets',
    name: 'model-sets',
    component: ModelSet,
    meta: {
      title: 'Model Sets',
    },
  },
  {
    path: '/packs',
    name: 'packs',
    component: Pack,
    meta: {
      title: 'Packs',
    },
  },
  {
    path: '/extra-content',
    name: 'extra-content',
    component: ExtraContent,
    meta: {
      title: 'Extra Content',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: User,
    meta: {
      title: 'Usuários',
    },
  },
  {
    path: '/orders/:userId',
    name: 'orders',
    component: Orders,
    meta: {
      title: 'Histórico de compras',
    },
  },
  {
    path: '/tags',
    name: 'tags',
    component: Tag,
    meta: {
      title: 'Tags',
    },
  },
];

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  return token != null;
};

const isAdmin = () => {
  const token = localStorage.getItem('token');
  const payload = jwt.decode(token);
  return payload.admin === 1;
};

const hasPermission = (permission) => {
  const token = localStorage.getItem('token');
  const payload = jwt.decode(token);
  return payload.admin === 1 || payload.permissoes.includes(permission);
};

router.beforeEach((to, from, next) => {
  if (!isAuthenticated() && to.meta.public !== true) {
    next('/login');
    return;
  }
  if (to.meta.admin && !isAdmin()) {
    next('/');
    return;
  }
  if (to.meta.permission && !hasPermission(to.meta.permission)) {
    next('/');
    return;
  }
  next();
});

export default router;
