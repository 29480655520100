<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="3">
        <v-form lazy-validation @submit.prevent="requestPasswordRecovery">
          <v-card class="pa-2" elevation="5" shaped>
            <router-link to="/">
              <v-img :src="require('../assets/artificers_h.png')" contain height="80px"></v-img>
            </router-link>
            <v-card-text>
              <v-text-field hide-details v-model="form.email" label="Email" type="email"></v-text-field>
            </v-card-text>
            <v-row>
              <v-col cols="12">
                <v-btn type="submit" block tile color="black" dark>Recuperar Senha</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="error">
              <v-col cols="12">
                <div class="overline red--text">{{error}}</div>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    error: '',
    form: {
      email: '',
    },
  }),
  methods: {
    requestPasswordRecovery() {
      this.$store
        .dispatch('recoverPassword', this.form)
        .then(() => {
          this.$router.push('/', () => {});
        });
    },
  },
};
</script>
