<template>
  <v-data-table
    :headers="headers"
    :items="filteredRecords"
    :search="search"
    :loading="loading"
    sort-by="name"
    no-data-text="Nenhum registro encontrado"
    no-results-text="Nenhum registro encontrado"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-col>
          <v-dialog v-model="dialog" max-width="50%" scrollable>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" @click="resetItem">Adicionar</v-btn>
            </template>
            <v-card>
              <v-card-title class="blue-grey lighten-5">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="10">
                        <v-text-field v-model="editedItem.name" label="Name" :rules="[rules.required]"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-select label="Tipo" :items="UserTypes" v-model="editedItem.type">
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" v-if="!editMode">
                        <v-text-field
                          v-model="editedItem.email"
                          label="E-mail"
                          type="email"
                          :rules="[rules.required, rules.email]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.password"
                          label="Password"
                          type="password"
                          :rules="editMode ? [] : [rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save" :loading="loadingBtn">Salvar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col>
          <v-switch
            v-model="showActive"
            :label="`Status: ${statusLabel}`"
            :true-value="1"
            :false-value="0"
          ></v-switch>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-toolbar>
    </template>
    <template v-slot:item.type="{item}">
      {{userType(item.type)}}
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon class="mr-2" @click="goToOrderHistory(item)" title="histórico">mdi-clipboard-text-clock-outline</v-icon>
      <v-icon class="mr-2" @click="editItem(item)" title="editar">mdi-square-edit-outline</v-icon>
      <v-icon @click="changeItemStatus(item)" :title="showActive === 1 ? 'Desativar' : 'Reativar'">
        {{showActive === 1 ? 'mdi-account-off-outline' : 'mdi-account-check-outline'}}
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { userTypes } from '@/store/constants';

const UserTypes = Object.entries(userTypes).map((entry) => ({ text: entry[0], value: entry[1] }));

export default {
  data: () => ({
    loading: true,
    loadingBtn: false,
    dialog: false,
    search: '',
    showActive: 1,
    UserTypes,
    headers: [
      {
        text: 'Nome',
        align: 'left',
        value: 'name',
      },
      { text: 'E-mail', value: 'email' },
      { text: 'Tipo', value: 'type' },
      {
        text: 'Ações',
        value: 'action',
        sortable: false,
        align: 'right',
      },
    ],
    records: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      password: null,
      type: userTypes.User,
    },
    defaultItem: {
      name: '',
      password: null,
      type: userTypes.User,
    },
    rules: {
      required: (value) => !!value || 'Obrigatório',
      email: (value) => {
        // eslint-disable-next-line max-len
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail.';
      },
    },
  }),

  computed: {
    editMode() {
      return this.editedIndex > -1;
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Novo usuário' : 'Editar usuário';
    },
    statusLabel() {
      return this.showActive === 1 ? 'Ativo' : 'Inativo';
    },
    filteredRecords() {
      return this.records.filter((item) => item.active === this.showActive);
    },
  },

  watch: {
    dialog(val) {
      if (val == null) {
        this.close();
      }
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loading = true;
      this.$store.dispatch('getAllUsers').then((response) => {
        this.loading = false;
        this.records = response;
      });
    },

    userType(type) {
      const found = this.UserTypes.find((entry) => entry.value === type);
      return found.text;
    },

    editItem(item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    goToOrderHistory(item) {
      this.$router.push(`/orders/${item.id}`);
    },

    changeItemStatus(item) {
      const index = this.records.indexOf(item);
      const toggle = this.records[index].active === 1 ? 0 : 1;
      this.$store.dispatch('editUser', { id: item.id, active: toggle })
        .then(() => {
          this.records[index].active = toggle;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetItem();
      }, 300);
    },

    resetItem() {
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loadingBtn = true;
      if (this.editedIndex > -1) {
        this.$store.dispatch('editUser', this.editedItem)
          .then(() => {
            this.loadingBtn = false;
            Object.assign(this.records[this.editedIndex], this.editedItem);
            this.close();
          });
      } else {
        this.$store.dispatch('createNewUser', this.editedItem)
          .then((response) => {
            this.loadingBtn = false;
            this.editedItem.id = response.insertedId;
            this.editedItem.active = 1;
            this.records.push(this.editedItem);
            this.$store.commit('showSnackbar', { text: response.message, color: 'success' });
            this.close();
          });
      }
    },
  },
};
</script>
