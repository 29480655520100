<template>
  <v-app>
    <v-navigation-drawer
      permanent
      v-model="drawer"
      app
      v-if="mainLayout"
    >
      <v-img
        :src="logo"
        max-width="234"
        class="ma-4"
        contain
      ></v-img>
      <v-list dense subheader>
        <v-divider class="my-2"></v-divider>
        <v-list-item
          v-for="(item, index) in menu"
          v-bind:key="index"
          :to="item.route"
          link
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-toolbar flat v-if="mainLayout">
        <h2 class="ml-3">{{ $route.meta.title }}</h2>
        <v-spacer></v-spacer>
        <v-subheader>
          <div class="text-subtitle-2 mr-4">
            <v-icon color="success" class="mr-1" small>mdi-circle</v-icon
            >{{ userName }}
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="logout">
                <v-icon>mdi-logout</v-icon>
              </v-btn>
            </template>
            <span>Logout</span>
          </v-tooltip>
        </v-subheader>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="mt-5">
        <v-snackbar
          v-model="snackbar.visible"
          :bottom="snackbar.y === 'bottom'"
          :color="snackbar.color"
          :left="snackbar.x === 'left'"
          :multi-line="snackbar.mode === 'multi-line'"
          :right="snackbar.x === 'right'"
          :timeout="snackbar.timeout"
          :top="snackbar.y === 'top'"
          :vertical="snackbar.mode === 'vertical'"
        >
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
              >Fechar</v-btn
            >
          </template>
        </v-snackbar>
        <router-view class="route-content"></router-view>
        <v-footer absolute padless class="overline footer">
          <v-col class="text-left"
            >Artificers — {{ new Date().getFullYear() }}</v-col
          >
          <v-col class="text-right"
            >Versão {{ version }}/{{ backendVersion }}</v-col
          >
        </v-footer>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
.footer {
  background-color: white !important;
  border-top: 1px solid #e7eaec !important;
}

.route-content {
  margin-bottom: 56px;
}
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { version } from '../package.json';

const miniLogo = require('./assets/artificers_v.png');
const logo = require('./assets/artificers_h.png');

export default {
  name: 'App',

  data: () => ({
    menu: [
      {
        icon: 'mdi-cube-outline',
        label: 'Models',
        route: '/models',
      },
      {
        icon: 'mdi-cube-scan',
        label: 'Model Sets',
        route: '/model-sets',
      },
      {
        icon: 'mdi-package-variant',
        label: 'Packs',
        route: '/packs',
      },
      {
        icon: 'mdi-puzzle-star-outline',
        label: 'Extra Content',
        route: '/extra-content',
      },
      {
        icon: 'mdi-tag-multiple-outline',
        label: 'Tags',
        route: '/tags',
      },
      {
        icon: 'mdi-account-group',
        label: 'Users',
        route: '/users',
      },
    ],
    drawer: true,
    mini: false,
    backendVersion: 0,
    version,
  }),
  created() {
    axios.get(`${process.env.VUE_APP_ENDPOINT}/version`).then((res) => {
      this.backendVersion = res.data.version;
    }).catch(() => {
      this.backendVersion = 'offline';
    });
    this.$store.commit('loadLocalStorage');
  },
  mounted() {
    this.mini = this.$vuetify.breakpoint.smAndDown;
  },
  computed: {
    ...mapGetters(['userName', 'snackbar']),
    userInitials() {
      return this.userName
        .split(' ')
        .reduce((initials, word) => initials + word[0].toUpperCase(), '');
    },
    logo() {
      return this.mini ? miniLogo : logo;
    },
    mainLayout() {
      return !this.$route.meta.public;
    },
  },
  methods: {
    logout() {
      this.$store.commit('clearLocalStorage');
      this.$store.commit('clearError');
      this.$router.push('login');
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      document.title = to.meta.title || 'Artificers Admin';
    },
  },
};
</script>
