<template>
  <v-data-table
    :headers="headers"
    :items="records"
    :search="search"
    :loading="loading"
    sort-by="nome"
    class="elevation-1"
    no-data-text="No tag in database"
    no-results-text="No record found for the current query"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-col>
          <v-dialog v-model="dialog" max-width="40%">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on="on"
                @click="resetItem"
                >Add tag</v-btn
              >
            </template>
            <v-card>
              <v-card-title class="blue-grey lighten-5">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea v-model="editedItem.description" auto-grow rows="1" label="Description"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save" :loading="savingForm">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon class="mr-2" @click="editItem(item)">mdi-square-edit-outline</v-icon>
      <v-icon @click="removeItem(item)">mdi-trash-can-outline</v-icon>
    </template>
  </v-data-table>
</template>

<script>

export default {
  data: () => ({
    loading: true,
    savingForm: false,
    dialog: false,
    search: '',
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'name',
      },
      { text: 'Description', value: 'description' },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        width: '150px',
        align: 'right',
      },
    ],
    records: [],
    models: [],
    editedIndex: -1,
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
    rules: {
      required: (value) => !!value || 'Required',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Tag' : 'Edit Tag';
    },
  },

  watch: {
    dialog(val) {
      if (val == null) {
        this.close();
      }
    },
  },

  created() {
    this.loadTags();
  },

  methods: {
    loadTags() {
      this.loading = true;
      this.$store.dispatch('getAllTags').then((response) => {
        this.loading = false;
        this.records = response;
      });
    },

    editItem(item) {
      this.editedIndex = this.records.indexOf(item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetItem();
      }, 300);
    },

    resetItem() {
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },

    removeItem(item) {
      const itemIndex = this.records.indexOf(item);
      this.$store.dispatch('removeTag', item.id).then((response) => {
        if (response.success) {
          this.records.splice(itemIndex, 1);
          this.loadTags();
        }
      });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.savingForm = true;
      if (this.editedIndex > -1) {
        this.$store.dispatch('editTag', this.editedItem).then((response) => {
          this.savingForm = false;
          if (response.success) {
            this.loadTags();
          }
          this.close();
        });
      } else {
        this.$store
          .dispatch('createTag', this.editedItem)
          .then((response) => {
            this.savingForm = false;
            if (response.success) {
              this.editedItem.id = response.insertId;
              this.records.push(this.editedItem);
              this.loadTags();
            }
            this.close();
          });
      }
    },
  },
};
</script>
