<template>
  <v-data-table
    :headers="headers"
    :items="records"
    :search="search"
    :loading="loading"
    sort-by="nome"
    class="elevation-1"
    no-data-text="No model in database"
    no-results-text="No record found for the current query"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-col>
          <v-dialog v-model="dialog" max-width="60%">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on="on"
                @click="resetItem"
                >Add model</v-btn
              >
            </template>
            <v-card>
              <v-card-title class="blue-grey lighten-5">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col cols="12" v-if="editedIndex !== -1">
                        <v-text-field
                          :value="editedItem.s3_id"
                          disabled
                          label="AWS S3 Model Identifier (Auto generated)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-switch v-model="editedItem.is_reward" :true-value="1" :false-value="0" label="Is Reward?">
                        </v-switch>
                      </v-col>
                      <v-col cols="12">
                        <vue-editor
                          v-model="editedItem.description"
                          label="Description"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.tags"
                          :items="tags"
                          chips
                          hide-selected
                          color="blue-grey lighten-2"
                          label="Tags"
                          item-text="name"
                          item-value="id"
                          multiple
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="removeFromTags(data.item)"
                            >
                              <v-avatar left>
                                <v-icon>mdi-tag-outline</v-icon>
                              </v-avatar>
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                              <v-list-item-avatar>
                                <v-icon>mdi-tag-outline</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.name"
                                ></v-list-item-title>
                              </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" v-if="editedIndex !== -1">
                        <v-combobox
                          label="Current Files"
                          multiple
                          v-model="editedItem.currentFiles"
                          :items="editedItem.currentFiles"
                        >
                        </v-combobox>
                      </v-col>
                      <v-col cols="12" v-if="editedIndex !== -1">
                        <v-combobox
                          label="Current Images"
                          multiple
                          v-model="editedItem.currentImages"
                          :items="editedItem.currentImages"
                        >
                        </v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          :accept="fileTypes"
                          label="Files"
                          placeholder="Upload all in one go for multiple"
                          chips
                          counter
                          multiple
                          show-size
                          small-chips
                          prepend-icon="mdi-printer-3d-nozzle-outline"
                          v-model="editedItem.files"
                          :rules="[rules.required]"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          :accept="imageTypes"
                          label="Images"
                          placeholder="Upload all in one go for multiple"
                          chips
                          counter
                          multiple
                          show-size
                          small-chips
                          prepend-icon="mdi-camera"
                          v-model="editedItem.images"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :loading="savingForm"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-overlay :value="savingForm && editedItem.files.length > 0">
              <v-progress-circular
                rotate="-90"
                :size="100"
                :width="15"
                :value="uploadProgress"
                color="primary"
              ></v-progress-circular>
            </v-overlay>
          </v-dialog>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-toolbar>
    </template>
    <template v-slot:item.is_reward="{ item }">
      <v-icon v-if="item.is_reward === 1">
        mdi-crown-outline
      </v-icon>
    </template>
    <template v-slot:item.description="{ item }">
      <div class="description-text" v-html="item.description"></div>
    </template>
    <template v-slot:item.files="{ item }">
      <v-list dense>
        <v-list-item v-for="(file, i) in item.files" :key="i">
          <v-list-item-content>
            <v-list-item-title v-text="fileEnd(file)"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:item.images="{ item }">
      <div class="py-4">
        <v-img :src="`${imageBaseUrl}${item.images[0]}`" max-width="256" contain v-if="item.images.length === 1"></v-img>
        <v-carousel height="210" v-else-if="item.images.length > 1" continuous hide-delimiters :show-arrows="false" cycle>
          <v-carousel-item v-for="(image) in item.images"
            max-width="256" contain :key="image" :src="`${imageBaseUrl}${image}`"
            reverse-transition="fade-transition"
            transition="fade-transition">
          </v-carousel-item>
        </v-carousel>
        <v-skeleton-loader type="image" v-else></v-skeleton-loader>
      </div>
    </template>
    <template v-slot:item.tags="{ item }">
      <v-list dense>
          <v-list-item v-for="(tagName, i) in tagNames(item.tags)" :key="i" class="px-2">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-tag-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="tagName"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="mr-2" @click="copyToClipboard(item)" v-on="on">
            mdi-folder-upload-outline
            </v-icon>
        </template>
        <span>Copy S3 Id to clipboard</span>
      </v-tooltip>
      <v-icon class="mr-2" @click="editItem(item)">
        mdi-square-edit-outline
      </v-icon>
      <v-icon @click="removeItem(item)">mdi-trash-can-outline</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { fileTypes, imageTypes, imageBaseUrl } from '../store/constants';

export default {
  data: () => ({
    loading: true,
    savingForm: false,
    dialog: false,
    search: '',
    fileTypes,
    imageTypes,
    tags: [],
    imageBaseUrl,
    headers: [
      { text: 'Reward', value: 'is_reward' },
      {
        text: 'Name',
        align: 'left',
        value: 'name',
      },
      { text: 'Description', value: 'description' },
      { text: 'Files', value: 'files' },
      { text: 'Tags', value: 'tags' },
      { text: 'Images', value: 'images', width: '20vw' },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        width: '150px',
        align: 'right',
      },
    ],
    records: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      s3_id: '',
      description: '',
      is_reward: 0,
      files: [],
      images: [],
      tags: [],
    },
    defaultItem: {
      name: '',
      s3_id: '',
      description: '',
      is_reward: 0,
      files: [],
      images: [],
      tags: [],
    },
    rules: {
      required: (value) => !!value || 'Required',
    },
  }),

  computed: {
    ...mapGetters(['uploadProgress']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Model' : 'Edit Model';
    },
  },

  watch: {
    dialog(val) {
      if (val == null) {
        this.close();
      }
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loadModels();
      this.$store.dispatch('getAllTags').then((response) => {
        this.tags = response;
      });
    },

    fileEnd(filepath) {
      const split = filepath.split('/');
      return split[split.length - 1];
    },

    loadModels() {
      this.loading = true;
      this.$store.dispatch('getAllModels').then((response) => {
        this.loading = false;
        this.records = response;
      });
    },

    tagNames(tagsIds) {
      return tagsIds.map((tagId) => {
        const found = this.tags.find((tag) => tag.id === tagId);
        return found?.name ?? '';
      });
    },

    removeFromTags(tag) {
      const tagIndex = this.editedItem.tags.indexOf(tag.id);
      if (tagIndex >= 0) {
        this.editedItem.tags.splice(tagIndex, 1);
      }
    },

    copyToClipboard(model) {
      this.$store.dispatch('copyToClipboard', { content: model.s3_id, successMessage: 'S3 Id copied to clipboard!' });
    },

    editItem(item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = { ...item, files: [], images: [] };
      this.editedItem.currentFiles = item.files;
      this.editedItem.currentImages = item.images;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetItem();
      }, 300);
    },

    resetItem() {
      this.editedItem = { ...this.defaultItem, s3_id: uuidv4() };
      this.editedIndex = -1;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },

    removeItem(item) {
      const itemIndex = this.records.indexOf(item);
      this.$store.dispatch('removeModel', item.id).then((response) => {
        if (response.success) {
          this.records.splice(itemIndex, 1);
          this.loadModels();
        }
      });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.savingForm = true;
      const editMode = this.editedIndex > -1;
      this.$store.dispatch('saveModelForm', { form: this.editedItem, editMode })
        .then(((response) => {
          this.savingForm = false;
          if (!response.success) {
            this.close();
            return;
          }
          if (editMode) {
            this.editedItem.id = response.insertId;
            this.records.push(this.editedItem);
          }
          this.loadModels();
          this.close();
        }));
    },
  },
};
</script>
