<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-form lazy-validation @submit.prevent="requestLogin">
          <v-card class="pa-2" elevation="5" shaped>
            <v-img :src="require('../assets/artificers_h.png')" class="ma-6" contain height="60px"></v-img>
            <v-card-text>
              <v-text-field hide-details v-model="form.email" label="Email" type="email"></v-text-field>
              <v-text-field hide-details v-model="form.password" label="Senha" type="password"></v-text-field>
            </v-card-text>
            <v-row>
              <v-col cols="12">
                <v-btn type="submit" block tile color="black" dark>Login</v-btn>
              </v-col>
              <v-col>
                <v-btn x-small href="/recover-password" text color="indigo">Esqueceu sua senha</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="error">
              <v-col cols="12">
                <div class="overline red--text">{{error}}</div>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  data: () => ({
    error: '',
    form: {
      email: '',
      password: '',
    },
  }),
  methods: {
    requestLogin() {
      this.$store.dispatch('logUserIn', this.form)
        .then(() => {
          this.$router.push('/', () => {});
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
  },
};
</script>
