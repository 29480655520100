import axios from 'axios';

const request = (state, commit, method, url, data) => axios({
  baseURL: process.env.VUE_APP_ENDPOINT,
  url,
  method,
  data,
  headers: {
    Authorization: `Bearer ${state.token}`,
  },
  onUploadProgress: (progressEvent) => {
    const current = progressEvent.loaded / progressEvent.total;
    const percentage = Math.round(current * 100);
    commit('updateUploadProgress', parseInt(percentage, 10));
  },
})
  .then((res) => {
    const response = res.data;
    if (res.config.method !== 'get') {
      commit('updateUploadProgress', 0);
      if (!response.success) {
        throw new Error(response.error);
      }
      commit('showSnackbar', { text: response.message, color: 'success' });
    }
    return response;
  })
  .catch((error) => {
    if (error.response && error.response.status === 401) {
      commit('clearLocalStorage');
    }
    commit('updateUploadProgress', 0);
    const message = error.response ? error.response.data.error : error.message;
    commit('showSnackbar', { text: message, color: 'error' });
    return error.response || { data: { success: false, message } };
  });

export default {
  copyToClipboard({ commit }, payload) {
    // eslint-disable-next-line no-undef
    const data = [new ClipboardItem({ 'text/plain': new Blob([payload.content], { type: 'text/plain' }) })];
    navigator.clipboard.write(data).then(() => {
      commit('showSnackbar', { text: payload.successMessage, color: 'success' });
    }, () => {
      commit('showSnackbar', { text: 'Unable to write to clipboard. :-(', color: 'error' });
    });
  },
  logUserIn({ commit, state }, formData) {
    if (state.user != null) {
      return Promise.resolve();
    }
    return axios.post(`${process.env.VUE_APP_ENDPOINT}/auth/login`, formData)
      .then((response) => {
        if (response.data.success) {
          commit('setLoggedUser', response.data.token);
        } else {
          throw new Error(response.data.error);
        }
      });
  },
  recoverPassword({ commit, state }, formData) {
    if (state.user != null) {
      return Promise.reject(new Error('Você já está logado!'));
    }
    return request(state, commit, 'post', 'auth/password/recovery', formData);
  },
  resetPassword({ commit, state }, formData) {
    if (state.user != null) {
      return Promise.reject(new Error('Você já está logado!'));
    }
    return request(state, commit, 'post', 'auth/password/reset', formData);
  },
  getAllUsers({ state, commit }) {
    return request(state, commit, 'get', 'user/all');
  },
  getUserData({ state, commit }, userId) {
    return request(state, commit, 'get', `user/${userId}`);
  },
  createNewUser({ state, commit }, userData) {
    return request(state, commit, 'post', 'user/create', userData);
  },
  editUser({ state, commit }, userData) {
    return request(state, commit, 'post', 'user/edit', userData);
  },
  getAvailableRewards({ state, commit }) {
    return request(state, commit, 'get', 'model/loyalty-rewards');
  },
  getAllModels({ state, commit }) {
    return request(state, commit, 'get', 'model/all');
  },
  createModel({ state, commit }, modelData) {
    return request(state, commit, 'post', 'model/create', modelData);
  },
  editModel({ state, commit }, modelData) {
    return request(state, commit, 'post', 'model/edit', modelData);
  },
  removeModel({ state, commit }, modelId) {
    return request(state, commit, 'delete', `model/${modelId}`);
  },
  getAllModelSets({ state, commit }) {
    return request(state, commit, 'get', 'model-set/all');
  },
  createModelSet({ state, commit }, modelSetData) {
    return request(state, commit, 'post', 'model-set/create', modelSetData);
  },
  editModelSet({ state, commit }, modelSetData) {
    return request(state, commit, 'post', 'model-set/edit', modelSetData);
  },
  removeModelSet({ state, commit }, modelSetId) {
    return request(state, commit, 'delete', `model-set/${modelSetId}`);
  },
  getAllPacks({ state, commit }) {
    return request(state, commit, 'get', 'pack/all');
  },
  createPack({ state, commit }, packData) {
    return request(state, commit, 'post', 'pack/create', packData);
  },
  editPack({ state, commit }, packData) {
    return request(state, commit, 'post', 'pack/edit', packData);
  },
  removePack({ state, commit }, packId) {
    return request(state, commit, 'delete', `pack/${packId}`);
  },
  getAllTags({ state, commit }) {
    return request(state, commit, 'get', 'tag/all');
  },
  createTag({ state, commit }, tagData) {
    return request(state, commit, 'post', 'tag/create', tagData);
  },
  editTag({ state, commit }, tagData) {
    return request(state, commit, 'post', 'tag/edit', tagData);
  },
  removeTag({ state, commit }, tagId) {
    return request(state, commit, 'delete', `tag/${tagId}`);
  },
  getAllExtraContent({ state, commit }) {
    return request(state, commit, 'get', 'extra-content/all');
  },
  createExtraContent({ state, commit }, extraContentData) {
    return request(state, commit, 'post', 'extra-content/create', extraContentData);
  },
  editExtraContent({ state, commit }, extraContentData) {
    return request(state, commit, 'post', 'extra-content/edit', extraContentData);
  },
  removeExtraContent({ state, commit }, contentId) {
    return request(state, commit, 'delete', `extra-content/${contentId}`);
  },
  getUserOrderHistory({ state, commit }, userId) {
    return request(state, commit, 'get', `order/user/${userId}/history`);
  },
  getOrderEnums({ state, commit }) {
    return request(state, commit, 'get', 'order/enums');
  },
  createOrder({ state, commit }, orderData) {
    return request(state, commit, 'post', 'order/create', orderData);
  },
  editOrder({ state, commit }, orderData) {
    return request(state, commit, 'post', 'order/edit', orderData);
  },
  async uploadImages({ state, commit }, payload) {
    return request(state, commit, 'post', `images/${payload.target}`, payload.formData);
  },
  async saveModelForm({ dispatch }, payload) {
    const modelForm = new FormData();
    modelForm.append('name', payload.form.name);
    modelForm.append('is_reward', payload.form.is_reward ? 1 : 0);
    modelForm.append('description', payload.form.description);
    modelForm.append('s3_id', payload.form.s3_id);
    modelForm.append('tags', JSON.stringify(payload.form.tags));
    payload.form.files.forEach((file) => {
      modelForm.append('files', file);
    });

    if (payload.form.images.length > 0) {
      const imageForm = new FormData();
      imageForm.append('s3_id', payload.form.s3_id);
      payload.form.images.forEach((file) => {
        imageForm.append('images', file);
      });
      const response = await dispatch('uploadImages', { target: 'model', formData: imageForm });
      if (!response.success) {
        throw new Error('Failed to upload images');
      }
      modelForm.append('images', response.images);
    }

    if (payload.editMode) {
      modelForm.append('id', payload.form.id);
      modelForm.append('currentFiles', payload.form.currentFiles);
      modelForm.append('currentImages', payload.form.currentImages);
      return dispatch('editModel', modelForm);
    }
    return dispatch('createModel', modelForm);
  },
  async saveModelSetForm({ dispatch }, payload) {
    const modelSetForm = new FormData();
    modelSetForm.append('name', payload.form.name);
    modelSetForm.append('prefix', payload.form.prefix);
    modelSetForm.append('s3_id', payload.form.s3_id);
    modelSetForm.append('models_ids', JSON.stringify(payload.form.models_ids));
    payload.form.files.forEach((file) => {
      modelSetForm.append('files', file);
    });

    if (payload.form.images.length > 0) {
      const imageForm = new FormData();
      imageForm.append('s3_id', payload.form.s3_id);
      payload.form.images.forEach((file) => {
        imageForm.append('images', file);
      });
      const response = await dispatch('uploadImages', { target: 'model-set', formData: imageForm });
      if (!response.success) {
        throw new Error('Failed to upload images');
      }
      modelSetForm.append('images', response.images);
    }

    if (payload.editMode) {
      modelSetForm.append('id', payload.form.id);
      modelSetForm.append('currentFiles', payload.form.currentFiles);
      modelSetForm.append('currentImages', payload.form.currentImages);
      return dispatch('editModelSet', modelSetForm);
    }
    return dispatch('createModelSet', modelSetForm);
  },
  async savePackForm({ dispatch }, payload) {
    const packForm = new FormData();
    packForm.append('name', payload.form.name);
    packForm.append('description', payload.form.description);
    packForm.append('s3_id', payload.form.s3_id);
    packForm.append('stripe_price_id', payload.form.stripe_price_id);
    packForm.append('youtube_video', payload.form.youtube_video);
    packForm.append('publish_date', payload.form.publish_date);
    packForm.append('models_ids', JSON.stringify(payload.form.models_ids));
    packForm.append('model_sets_ids', JSON.stringify(payload.form.model_sets_ids));
    payload.form.files.forEach((file) => {
      packForm.append('files', file);
    });

    if (payload.form.images.length > 0) {
      const imageForm = new FormData();
      imageForm.append('s3_id', payload.form.s3_id);
      payload.form.images.forEach((file) => {
        imageForm.append('images', file);
      });
      const response = await dispatch('uploadImages', { target: 'pack', formData: imageForm });
      if (!response.success) {
        throw new Error('Failed to upload images');
      }
      packForm.append('images', response.images);
    }

    if (payload.editMode) {
      packForm.append('id', payload.form.id);
      packForm.append('currentFiles', payload.form.currentFiles);
      packForm.append('currentImages', payload.form.currentImages);
      return dispatch('editPack', packForm);
    }
    return dispatch('createPack', packForm);
  },
  async saveExtraContentForm({ dispatch }, payload) {
    const modelForm = new FormData();
    modelForm.append('name', payload.form.name);
    modelForm.append('s3_id', payload.form.s3_id);
    payload.form.files.forEach((file) => {
      modelForm.append('files', file);
    });

    if (payload.editMode) {
      modelForm.append('id', payload.form.id);
      modelForm.append('currentFiles', payload.form.currentFiles);
      return dispatch('editExtraContent', modelForm);
    }
    return dispatch('createExtraContent', modelForm);
  },

};
