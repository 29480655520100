const fileTypes = [
  '.stl',
  'model/stl',
  'application/sla',
  'application/vnd.ms-pki.stl',
  'application/gzip',
  'application/zip',
  'application/x-7z-compressed',
  'application/vnd.rar',
  'application/octet-stream',
  'application/pdf',
];

const imageTypes = [
  'image/jpeg', 'image/svg+xml', 'image/png', 'image/gif',
];

const imageBaseUrl = 'https://artificers-images.s3.amazonaws.com/';

const userTypes = {
  Admin: -1,
  User: 0,
};

module.exports = {
  fileTypes, imageTypes, imageBaseUrl, userTypes,
};
