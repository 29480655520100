<template>
  <v-card>
    <v-card-title class="text-h4 ml-4">
      {{ user?.name ?? '' }}
    </v-card-title>
    <v-data-table
    :headers="headers"
    :items="records"
    :search="search"
    :loading="loading"
    sort-by="name"
    no-data-text="Nenhum registro encontrado"
    no-results-text="Nenhum registro encontrado"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-col>
          <v-dialog v-model="dialog" max-width="50%" scrollable>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" @click="resetItem">Adicionar</v-btn>
            </template>
            <v-card>
              <v-card-title class="blue-grey lighten-5">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" v-if="editMode">
                        <v-text-field label="Data do evento" v-model="editedItem.reference_date" disabled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" v-if="!editMode">
                        <v-menu
                          v-model="datePickerMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.reference_date"
                              label="Data do evento"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.reference_date"
                            @input="datePickerMenu = false"
                            show-adjacent-months
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select :items="orderTypes" v-model="editedItem.order_type" label="Tipo" :rules="[rules.required]">
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field label="Valor" v-model="editedItem.value" prefix="$" type="number">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field label="Tokens" v-model="editedItem.tokens" type="number">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field label="Shards" v-model="editedItem.shards" type="number">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select :items="orderStatus" v-model="editedItem.status" label="Status" :rules="[rules.required]">
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.related_packs"
                          :items="packs"
                          hide-selected
                          chips
                          color="blue-grey lighten-2"
                          label="Aquired Packs"
                          item-text="name"
                          item-value="id"
                          multiple
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="removeFromSet(data.item, 'related_packs')"
                            >
                            {{ data.item.name }}
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{data.item.name}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.related_reward_id"
                          :items="rewards"
                          chips
                          color="blue-grey lighten-2"
                          label="Aquired Reward"
                          item-text="name"
                          item-value="id"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save" :loading="savingForm">Salvar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon class="mr-2" @click="editItem(item)" title="editar">mdi-square-edit-outline</v-icon>
    </template>
    <template v-slot:item.related_packs="{ item }">
      {{packNames(item.related_packs)}}
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
const getCurrentDate = () => (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

export default {
  data: () => ({
    loading: true,
    savingForm: false,
    packs: [],
    rewards: [],
    datePickerMenu: false,
    dialog: false,
    search: '',
    userId: null,
    user: null,
    showActive: 1,
    orderTypes: [],
    orderStatus: [],
    headers: [
      {
        text: 'Data',
        align: 'left',
        value: 'reference_date',
      },
      {
        text: 'Tipo',
        value: 'order_type',
      },
      { text: 'Status', value: 'status' },
      {
        text: 'Packs',
        value: 'related_packs',
      },
      {
        text: 'Value',
        value: 'value',
      },
      {
        text: 'Tokens',
        value: 'tokens',
      },
      {
        text: 'Shards',
        value: 'shards',
      },
      {
        text: 'Ações',
        value: 'action',
        sortable: false,
        align: 'right',
      },
    ],
    records: [],
    editedIndex: -1,
    editedItem: {
      reference_date: getCurrentDate(),
      order_type: null,
      status: null,
      related_packs: [],
      related_reward_id: null,
      value: 0,
      tokens: 0,
      shards: 0,
    },
    defaultItem: {
      reference_date: getCurrentDate(),
      order_type: null,
      status: null,
      related_packs: [],
      related_reward_id: null,
      value: 0,
      tokens: 0,
      shards: 0,
    },
    rules: {
      required: (value) => !!value || 'Obrigatório',
    },
  }),

  computed: {
    editMode() {
      return this.editedIndex > -1;
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Novo evento' : 'Editar evento';
    },
  },

  watch: {
    dialog(val) {
      if (val == null) {
        this.close();
      }
    },
  },
  beforeRouteUpdate(to) {
    this.userId = to.params.userId;
    this.loadUserHistory(to.params.userId);
  },

  created() {
    this.initialize();
  },

  methods: {
    packNames(packs) {
      const relatedPacks = this.packs.filter((pack) => packs.includes(pack.id));
      return relatedPacks.map((pack) => pack.name.trim()).join(', ');
    },
    initialize() {
      const { userId } = this.$route.params;
      this.userId = userId;
      this.loadUserHistory(userId);
      this.$store.dispatch('getAllPacks').then((response) => {
        this.packs = response;
      });
      this.$store.dispatch('getAvailableRewards').then((response) => {
        this.rewards = response;
      });
      this.$store.dispatch('getOrderEnums').then((response) => {
        this.orderTypes = response.orderTypes;
        this.orderStatus = response.orderStatus;
      });
    },

    loadUserHistory(userId) {
      this.loading = true;
      this.$store.dispatch('getUserOrderHistory', userId).then((response) => {
        this.loading = false;
        this.records = response;
      });
      this.$store.dispatch('getUserData', userId).then((response) => {
        this.user = response;
      });
    },

    editItem(item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    resetItem() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.editedItem = { ...this.defaultItem, order_type: this.orderTypes[0], status: this.orderStatus[0] };
      this.editedIndex = -1;
    },

    removeFromSet(obj, propName) {
      const modelIndex = this.editedItem[propName].indexOf(obj.id);
      if (modelIndex >= 0) {
        this.editedItem[propName].splice(modelIndex, 1);
      }
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.savingForm = true;
      this.editedItem.user_id = this.userId;
      if (this.editedIndex > -1) {
        this.$store.dispatch('editOrder', this.editedItem)
          .then(() => {
            this.savingForm = false;
            Object.assign(this.records[this.editedIndex], this.editedItem);
            this.close();
          });
      } else {
        this.$store.dispatch('createOrder', this.editedItem)
          .then((response) => {
            this.savingForm = false;
            this.editedItem.id = response.insertId;
            this.records.push(this.editedItem);
            this.loadUserHistory(this.userId);
            this.close();
          });
      }
    },
  },
};
</script>
