<template>
  <v-data-table
    :headers="headers"
    :items="records"
    :search="search"
    :loading="loading"
    sort-by="nome"
    class="elevation-1"
    no-data-text="No extra content in database"
    no-results-text="No record found for the current query"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-col>
          <v-dialog v-model="dialog" max-width="60%">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on="on"
                @click="resetItem"
                >Add extra content</v-btn
              >
            </template>
            <v-card>
              <v-card-title class="blue-grey lighten-5">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col cols="12" v-if="editedIndex !== -1">
                        <v-text-field
                          :value="editedItem.s3_id"
                          disabled
                          label="AWS S3 Model Identifier (Auto generated)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="editedIndex !== -1">
                        <v-combobox
                          label="Current Files"
                          multiple
                          v-model="editedItem.currentFiles"
                          :items="editedItem.currentFiles"
                        >
                        </v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          :accept="fileTypes"
                          label="Files"
                          placeholder="Upload all in one go for multiple"
                          chips
                          counter
                          multiple
                          show-size
                          small-chips
                          prepend-icon="mdi-printer-3d-nozzle-outline"
                          v-model="editedItem.files"
                          :rules="[rules.required]"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :loading="savingForm"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-overlay :value="savingForm && editedItem.files.length > 0">
              <v-progress-circular
                rotate="-90"
                :size="100"
                :width="15"
                :value="uploadProgress"
                color="primary"
              ></v-progress-circular>
            </v-overlay>
          </v-dialog>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-toolbar>
    </template>
    <template v-slot:item.files="{ item }">
      <v-list dense>
        <v-list-item v-for="(file, i) in item.files" :key="i">
          <v-list-item-content>
            <v-list-item-title v-text="fileEnd(file)"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="mr-2" @click="copyToClipboard(item)" v-on="on">
            mdi-folder-upload-outline
            </v-icon>
        </template>
        <span>Copy S3 Id to clipboard</span>
      </v-tooltip>
      <v-icon class="mr-2" @click="editItem(item)"
        >mdi-square-edit-outline</v-icon
      >
      <v-icon @click="removeItem(item)">mdi-trash-can-outline</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { fileTypes } from '../store/constants';

export default {
  name: 'ExtraContent',
  data: () => ({
    loading: true,
    savingForm: false,
    dialog: false,
    search: '',
    fileTypes,
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'name',
      },
      { text: 'Files', value: 'files' },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        width: '150px',
        align: 'right',
      },
    ],
    records: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      s3_id: '',
      files: [],
    },
    defaultItem: {
      name: '',
      s3_id: '',
      files: [],
    },
    rules: {
      required: (value) => !!value || 'Required',
    },
  }),

  computed: {
    ...mapGetters(['uploadProgress']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Content' : 'Edit Content';
    },
  },

  watch: {
    dialog(val) {
      if (val == null) {
        this.close();
      }
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loadExtraContent();
    },

    fileEnd(filepath) {
      const split = filepath.split('/');
      return split[split.length - 1];
    },

    loadExtraContent() {
      this.loading = true;
      this.$store.dispatch('getAllExtraContent').then((response) => {
        this.loading = false;
        this.records = response;
      });
    },

    copyToClipboard(model) {
      this.$store.dispatch('copyToClipboard', { content: model.s3_id, successMessage: 'S3 Id copied to clipboard!' });
    },

    editItem(item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = { ...item, files: [] };
      this.editedItem.currentFiles = item.files;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetItem();
      }, 300);
    },

    resetItem() {
      this.editedItem = { ...this.defaultItem, s3_id: uuidv4() };
      this.editedIndex = -1;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },

    removeItem(item) {
      const itemIndex = this.records.indexOf(item);
      this.$store.dispatch('removeExtraContent', item.id).then((response) => {
        if (response.success) {
          this.records.splice(itemIndex, 1);
          this.loadExtraContent();
        }
      });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.savingForm = true;
      const editMode = this.editedIndex > -1;
      this.$store.dispatch('saveExtraContentForm', { form: this.editedItem, editMode })
        .then(((response) => {
          this.savingForm = false;
          if (!response.success) {
            this.close();
            return;
          }
          if (editMode) {
            this.editedItem.id = response.insertId;
            this.records.push(this.editedItem);
          }
          this.loadExtraContent();
          this.close();
        }));
    },
  },
};
</script>
