<template>
  <v-data-table
    :headers="headers"
    :items="records"
    :search="search"
    :loading="loading"
    sort-by="nome"
    class="elevation-1"
    no-data-text="No pack in database"
    no-results-text="No record found for the current query"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-col>
          <v-dialog v-model="dialog" max-width="40%">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" @click="resetItem">Add pack</v-btn>
            </template>
            <v-card>
              <v-card-title class="blue-grey lighten-5">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col cols="12" v-if="editedIndex !== -1">
                        <v-text-field
                          :value="editedItem.s3_id"
                          disabled
                          label="AWS S3 Model Identifier (Auto generated)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-menu
                          v-model="datePickerMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.publish_date"
                              label="Publishing date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.publish_date"
                            @input="datePickerMenu = false"
                            show-adjacent-months
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.stripe_price_id"
                          label="Stripe Price Id"
                          placeholder="Ex: price_1MK4WkKkerQgAC8RmGibJD4I"
                          hint="Create product on stripe with US/BR pricing"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.youtube_video"
                          label="Youtube Video Id"
                          placeholder="Ex: 6zTBtzQIgsc"
                          hint="Use only the video id"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <vue-editor v-model="editedItem.description" label="Description" />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.model_sets_ids"
                          :items="modelSets"
                          hide-selected
                          chips
                          color="blue-grey lighten-2"
                          label="Models Sets"
                          :item-text="(item) => `${item.prefix} - ${item.name}`"
                          item-value="id"
                          multiple
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="removeFromSet(data.item, 'model_sets_ids')"
                            >
                              {{ data.item.prefix }} - {{ data.item.name }}
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ data.item.prefix }} - {{ data.item.name }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.models_ids"
                          :items="models"
                          filled
                          chips
                          color="blue-grey lighten-2"
                          label="Models"
                          item-text="name"
                          item-value="id"
                          multiple
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="removeFromSet(data.item, 'models_ids')"
                            >
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" v-if="editedIndex !== -1">
                        <v-combobox
                          label="Current Images"
                          multiple
                          v-model="editedItem.currentImages"
                          :items="editedItem.currentImages"
                        >
                        </v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          :accept="imageTypes"
                          label="Images"
                          placeholder="Upload all in one go for multiple"
                          chips
                          counter
                          multiple
                          show-size
                          small-chips
                          prepend-icon="mdi-camera"
                          v-model="editedItem.images"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save" :loading="savingForm">Save</v-btn>
              </v-card-actions>
            </v-card>
            <v-overlay :value="savingForm && editedItem.files.length > 0">
              <v-progress-circular
                rotate="-90"
                :size="100"
                :width="15"
                :value="uploadProgress"
                color="primary"
              ></v-progress-circular>
            </v-overlay>
          </v-dialog>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-toolbar>
    </template>
    <template v-slot:item.publish_date="{ item }">
      <div>
        {{ formatPackDate(item.publish_date) }}
      </div>
    </template>
    <template v-slot:item.description="{ item }">
      <div class="description-text" v-html="item.description"></div>
    </template>
    <template v-slot:item.models_ids="{ item }">
      <v-list dense>
        <v-list-item v-for="(model, i) in modelsData(item)" :key="i">
          <v-list-item-content>
            <v-list-item-title v-text="model.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:item.model_sets_ids="{ item }">
      <v-list dense>
        <v-list-item v-for="(modelSets, i) in modelSetsData(item)" :key="i">
          <v-list-item-content>
            <v-list-item-title>{{ modelSets.prefix }} - {{ modelSets.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:item.images="{ item }">
      <div class="py-4">
        <v-img
          :src="`${imageBaseUrl}${item.images[0]}`"
          max-width="512"
          contain
          v-if="item.images.length === 1"
        ></v-img>
        <v-carousel
          height="410"
          v-else-if="item.images.length > 1"
          continuous
          hide-delimiters
          :show-arrows="false"
          cycle
        >
          <v-carousel-item
            v-for="image in item.images"
            max-width="512"
            contain
            :key="image"
            :src="`${imageBaseUrl}${image}`"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
          </v-carousel-item>
        </v-carousel>
        <v-skeleton-loader type="image" v-else></v-skeleton-loader>
      </div>
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="mr-2" @click="copyToClipboard(item)" v-on="on">
            mdi-folder-upload-outline
          </v-icon>
        </template>
        <span>Copy S3 Id to clipboard</span>
      </v-tooltip>
      <v-icon class="mr-2" @click="editItem(item)">mdi-square-edit-outline</v-icon>
      <v-icon @click="removeItem(item)">mdi-trash-can-outline</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { parseJSON, format } from 'date-fns';
import { fileTypes, imageTypes, imageBaseUrl } from '../store/constants';

const getCurrentDate = () => new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);

export default {
  data: () => ({
    loading: true,
    savingForm: false,
    dialog: false,
    datePickerMenu: false,
    search: '',
    fileTypes,
    imageTypes,
    imageBaseUrl,
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'name',
      },
      { text: 'Publish Date', value: 'publish_date' },
      { text: 'Description', value: 'description' },
      { text: 'Model Sets', value: 'model_sets_ids' },
      { text: 'Images', value: 'images', width: '20vw' },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        width: '150px',
        align: 'right',
      },
    ],
    records: [],
    models: [],
    modelSets: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      s3_id: '',
      description: '',
      files: [],
      images: [],
      models_ids: [],
      model_sets_ids: [],
      publish_date: getCurrentDate(),
      stripe_price_id: '',
      youtube_video: '',
    },
    defaultItem: {
      name: '',
      s3_id: '',
      description: '',
      files: [],
      images: [],
      models_ids: [],
      model_sets_ids: [],
      publish_date: getCurrentDate(),
      stripe_price_id: '',
      youtube_video: '',
    },
    rules: {
      required: (value) => !!value || 'Required',
    },
  }),

  computed: {
    ...mapGetters(['uploadProgress']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Pack' : 'Edit Pack';
    },
  },

  watch: {
    dialog(val) {
      if (val == null) {
        this.close();
      }
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loading = true;
      this.$store.dispatch('getAllPacks').then((response) => {
        this.loading = false;
        this.records = response;
      });
      this.$store.dispatch('getAllModelSets').then((response) => {
        this.loading = false;
        this.modelSets = response;
      });
      this.$store.dispatch('getAllModels').then((response) => {
        this.models = response;
      });
    },

    formatPackDate(date) {
      const parsedDate = parseJSON(JSON.stringify(date));
      return format(parsedDate, 'MMMM, y');
    },
    removeFromSet(obj, propName) {
      const modelIndex = this.editedItem[propName].indexOf(obj.id);
      if (modelIndex >= 0) {
        this.editedItem[propName].splice(modelIndex, 1);
      }
    },
    modelsData(pack) {
      return this.models.filter((m) => pack.models_ids.includes(m.id));
    },
    modelSetsData(pack) {
      return this.modelSets.filter((m) => pack.model_sets_ids.includes(m.id));
    },

    copyToClipboard(pack) {
      this.$store.dispatch('copyToClipboard', {
        content: pack.s3_id,
        successMessage: 'S3 Id copied to clipboard!',
      });
    },

    editItem(item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = {
        ...item,
        files: [],
        images: [],
      };
      this.editedItem.currentFiles = item.files;
      this.editedItem.currentImages = item.images;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.resetItem();
      }, 300);
    },

    resetItem() {
      this.editedItem = { ...this.defaultItem, s3_id: uuidv4() };
      this.editedIndex = -1;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },

    removeItem(item) {
      const itemIndex = this.records.indexOf(item);
      this.$store.dispatch('removePack', item.id).then((response) => {
        if (response.success) {
          this.records.splice(itemIndex, 1);
          this.initialize();
        }
      });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.savingForm = true;
      const editMode = this.editedIndex > -1;
      this.$store.dispatch('savePackForm', { form: this.editedItem, editMode }).then((response) => {
        this.savingForm = false;
        if (!response.success) {
          this.close();
          return;
        }
        if (editMode) {
          this.editedItem.id = response.insertId;
          this.records.push(this.editedItem);
        }
        this.initialize();
        this.close();
      });
    },
  },
};
</script>
