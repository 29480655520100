import jwt from 'jsonwebtoken';

export default {
  setLoggedUser(state, payload) {
    state.user = jwt.decode(payload);
    state.user.permissoes = state.user.permissoes || [];
    state.token = payload;
    localStorage.setItem('token', payload);
  },
  setPendingCount(state, payload) {
    state.pendingCount = payload;
  },
  loadLocalStorage(state) {
    const userJWT = localStorage.getItem('token');
    state.token = userJWT;
    state.user = jwt.decode(userJWT);
  },
  clearLocalStorage(state) {
    state.user = null;
    localStorage.removeItem('token');
  },
  showSnackbar(state, payload) {
    state.snackbar.visible = true;
    state.snackbar.text = payload.text;
    state.snackbar.color = payload.color;
  },
  updateUploadProgress(state, payload) {
    state.uploadProgress = payload;
  },
};
